import React from "react";
import OldGlory from "../assets/images/flag.jpg";
import "../styles/About.css";

function About() {
    return (
        <div className="about">
            <div
                className="aboutTop"
                style={{ backgroundImage: `url(${OldGlory})`}}
            ></div>
            <div className="aboutBottom">
                <h1> ABOUT US</h1>
                <p>
                    The Outpost aims to improve your lifestyle by offering modern applications
                    to solve problems. Our approach provides prodoctivity applications while 
                    keeping your privacy and personal information in mind. We are a network of
                    veterans. 
                </p>
            </div>
        </div>
    );
}

export default About;