import React from "react";
import "../styles/Home.css";

function Home() {

    const handleClick = () => {
        window.open("https://apps.apple.com/us/app/acft/id1619956364");
      };

    return (
        <div>
            <div className="home">
                <div className="headerContainer">
                    <h1> The Outpost </h1>
                    <p> Digital Solutions</p>
                    <button onClick={handleClick}>
                        ACFT iOS
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Home;